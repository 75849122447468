import React from "react";

const hexToFilter = (hexColor: string) => {
    const filters: { [key: string]: string } = {
        "#246BFD": "brightness(0) saturate(100%) invert(30%) sepia(72%) saturate(3136%) hue-rotate(215deg) brightness(100%) contrast(99%)",
    };
    return filters[hexColor as keyof typeof filters] || "";
};

console.log('')

const Logo = ({ color = "#246BFD" }) => (
    <img
        src={'/img/images/logo.png'}
        alt="Logo"
        style={{
            width: "130px",
            height: "auto",
            filter: hexToFilter(color),
        }}
    />
);

export default Logo;
