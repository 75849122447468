import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import Logo from "../../common/Logo";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  // The local scroll function
  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setVisibility(false);
  };

  // This function either scrolls locally (if on home page),
  // or navigates to home page with the requested section in state.
  const handleNavClick = (sectionId: string) => {
    if (location.pathname !== "/") {
      // Navigate to "/", pass in the target section via state
      navigate("/", { state: { scrollToId: sectionId } });
    } else {
      // Already on home page, just scroll
      scrollTo(sectionId);
    }
  };

  // Your menu items
  const MenuItem = () => {
    return (
        <>
          <CustomNavLinkSmall onClick={() => handleNavClick("about")}>
            <Span>{t("About")}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={() => handleNavClick("product")}>
            <Span>{t("Experience")}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={() => handleNavClick("mission")}>
            <Span>{t("Download")}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall
              style={{ width: "180px" }}
              onClick={() => handleNavClick("contact")}
          >
            <Span>
              <Button>{t("Contact")}</Button>
            </Span>
          </CustomNavLinkSmall>
        </>
    );
  };

  return (
      <HeaderSection>
        <Container>
          <Row justify="space-between">
            <LogoContainer to="/" aria-label="homepage">
              <Logo />
            </LogoContainer>
            <NotHidden>
              <MenuItem />
            </NotHidden>
            <Burger onClick={showDrawer}>
              <Outline />
            </Burger>
          </Row>
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <Label onClick={onClose}>
                <Col span={12}>
                  <Menu>Menu</Menu>
                </Col>
                <Col span={12}>
                  <Outline />
                </Col>
              </Label>
            </Col>
            <MenuItem />
          </Drawer>
        </Container>
      </HeaderSection>
  );
};

export default withTranslation()(Header);
