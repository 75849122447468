import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {
    return (
        <Suspense fallback={null}>
            <Styles />
            <Header />
            <Routes>
                {routes.map((routeItem) => {
                    const LazyPage = lazy(() => import(`../pages/${routeItem.component}`));

                    // If path is an array, map over it to create multiple <Route> elements:
                    if (Array.isArray(routeItem.path)) {
                        return routeItem.path.map((singlePath) => (
                            <Route
                                key={singlePath}
                                path={singlePath}
                                element={<LazyPage />}
                            />
                        ));
                    }

                    // Otherwise, just return a single route
                    return (
                        <Route
                            key={routeItem.path}
                            path={routeItem.path}
                            element={<LazyPage />}
                        />
                    );
                })}
            </Routes>
            <Footer />
        </Suspense>
    );
};

export default Router;
