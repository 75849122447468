const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: "/privacy-policy",
    component: "PrivacyPolicy",
  },
  {
    path: "/terms-of-service",
    component: "TermsOfService",
  },
]

export default routes;
