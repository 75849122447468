import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            key={src}
            aria-label={src}
        >
          <SvgIcon src={src} width="25px" height="25px" />
        </a>
    );
  };

  return (
      <>
        <FooterSection>
          <Container>
            <Row gutter={[24, 24]} justify="space-between" align="middle">
              {/* Contact */}
              <Col xs={24} sm={12} md={6} lg={6}>
                <Language>{t("Contact")}</Language>
                <Large to="/">{t("Tell us everything")}</Large>
                <Para>{t("Do you have any question? Feel free to reach out.")}</Para>
                <a href="mailto:general@splitme.co">
                  <Chat>{t("Let's Chat")}</Chat>
                </a>
              </Col>

              {/* Address */}
              <Col xs={24} sm={12} md={6} lg={6}>
                <Language>{t("Address")}</Language>
                <Para>University City</Para>
                <Para>3820 Locust Walk</Para>
                <Para>Philadelphia</Para>
              </Col>

              {/* Legal */}
              <Col xs={24} sm={12} md={6} lg={6}>
                <Language>{t("Legal")}</Language>
                <Para>
                  <NavLink to="/privacy-policy">
                    {t("Privacy Policy")}
                  </NavLink>
                </Para>
                <Para>
                  <NavLink to="/terms-of-service">
                    {t("Terms of Service")}
                  </NavLink>
                </Para>
              </Col>

              {/* Language */}
              <Col xs={24} sm={12} md={6} lg={6}>
                <Label htmlFor="select-lang">{t("Language")}</Label>
                <LanguageSwitchContainer>
                  <LanguageSwitch onClick={() => handleChange("en")}>
                    <SvgIcon
                        src="united-states.svg"
                        aria-label="homepage"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                </LanguageSwitchContainer>
              </Col>
            </Row>

          </Container>
        </FooterSection>
      </>
  );
};

export default withTranslation()(Footer);